<template>
  <rpm-layout>
    <div class="bg-white rounded-lg p-2">
      <p class="text-gray-700 text-2xl font-bold mb-2">Reports</p>
      <div class="flex gap-2 items-center mb-2">
        <ul>
          <li><router-link :to="{ name: 'rpm-dashboard-monthly-report' }" class="text-blue-500">Monthly Report</router-link></li>
          <li><router-link :to="{ name: 'rpm-dashboard-status-report' }" class="text-blue-500">Status Report</router-link></li>
        </ul>
      </div>
    </div>
  </rpm-layout>
</template>

<script>

import RpmLayout from "@/layouts/RpmLayout.vue";

export default {
  mixins: [],
  components: {RpmLayout},
  data() {
    return {

    };
  },
  created: async function () {

  },
  mounted: function () {
  },
  computed: {

  },
  methods: {

  },
};
</script>
